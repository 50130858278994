// Import React and ReactDOM
import React from 'react';
import ReactDOM from 'react-dom/client'; // Use 'react-dom/client' for React 18
import "./index.css";
// Import your main App component
import App from './App';

// Find the root DOM element in your HTML
const rootElement = document.getElementById('root');

// Create the root and render the App
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

